import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <h1>Bryllup - Ceremoni</h1>
    <div>
      Det sker i haven! Der har vi ikke plads til alle, så det er kun de
      nærmeste vi inviterer til denne del.
    </div>
    <Link to="/">Tilbage til oversigt</Link>
  </Layout>
);

export default IndexPage;
